import React from 'react'

const Copy = () => {
  return (
    <div>
        <p className='text-center'>&copy; 2024 Romsis. Tüm Hakları Saklıdır.</p>

    </div>
  )
}

export default Copy