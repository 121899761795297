export const referans =[
    {
      id:1,
      image:"/image/referans/pegasus.png",
      
    },
    {
      id:2,
      image:"/image/referans/ajet.png",
    },
    {
      id:3,
      image:"/image/referans/freebird.png",
     
    },
    {
      id:4,
      image:"/image/referans/mga.png",
     
    },
    {
      id:5,
      image:"/image/referans/yükselen.png",
   
   
    },
    {
      id:6,
      image:"/image/referans/jamak.png",
     
    },
    {
      id:7,
      image:"/image/referans/polyplex.png",
      
      
    },
    {
      id:8,
      image:"/image/referans/kiwi.png",
    },
    {
      id:9,
      image:"/image/referans/bni.png",
     
    },
    {
      id:10,
      image:"/image/referans/merkez.png",
    },
    {
      id:11,
      image:"/image/referans/sait.png",
     
    },
    {
      id:12,
      image:"/image/referans/basar.png",
      
    },
    {
      id:13,
      image:"/image/referans/guler.png",
      
    },
    {
      id:14,
      image:"/image/referans/teas.png",
    },
    {
      id:15,
      image:"/image/referans/adore.png",
    },
  ];
export const referanswms =[

    {
      id:2,
      image:"/image/referans/basar.png",
    },
    {
      id:3,
      image:"/image/referans/bni.png",
    },
    {
      id:4,
      image:"/image/referans/sait.png",
    },

    {
      id:6,
      image:"/image/referans/guler.png",
    },
    {
      id:7,
      image:"/image/referans/jamak.png",
    },
    {
      id:8,
      image:"/image/referans/kiwi.png",
    },
    {
      id:9,
      image:"/image/referans/yükselen.png",
    },
    {
      id:10,
      image:"/image/referans/merkez.png",
    },


    {
      id:13,
      image:"/image/referans/polyplex.png",
    },
    {
      id:14,
      image:"/image/referans/teas.png",
    },
    {
      id:15,
      image:"/image/referans/adore.png",
    },
  ];




